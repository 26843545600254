<template>
  <div>
    <b-modal
      id="legal-argument-modal"
      v-model="showModal"
      hide-footer
      hide-header
      size="lg"
    >
      <header class="memo-header">
        <div class="title-container">
          <h3 class="main-title">Create Legal Argument</h3>
          <p class="subtitle">Create a clear and effective legal argument</p>
        </div>
        <button class="close-button" aria-label="Close" @click="$emit('close')">
          <span class="material-symbols-rounded close-icon">close</span>
        </button>
      </header>
      <main class="memo-content">
        <form class="memo-form">
          <div class="input-section">
            <label for="question" class="input-label">Statement</label>
            <textarea
              v-model.trim="statement"
              id="question"
              autocorrect="off"
              autocomplete="off"
              v-validate="'required'"
              name="statement"
              class="input-field"
              placeholder="Input statement
For example: Soper really did die and his second wife Gertrude collected the insurance money. But then his original wife found out and came along claiming that since she was still his wife she should receive the insurance money. Only his first wife is entitled to claim the insurance money."
              rows="3"
              maxlength="200"
            ></textarea>
            <span class="character-count">{{ getCharCount(statement) }}/200</span>
          </div>
          <div class="jurisdiction-section">
            <label class="input-label">Choose Jurisdiction</label>
            <div class="country-list">
              <button
                v-for="(icon, country) in countryIcons"
                :key="country"
                class="country-button"
                :aria-label="`Select ${country}`"
                @click.prevent="selectCountry(country)"
                :class="{ 'selected': jurisdiction === country }"
              >
                <img :src="icon" :alt="`${country} flag`" class="country-flag" />
                <span>{{ country }}</span>
              </button>
            </div>
          </div>
        </form>
      </main>
      <footer class="memo-footer">
        <button class="cancel-button" @click="$emit('close')">Cancel</button>
        <button @click.prevent="GenerateArgument" :class="statement && jurisdiction ? 'generate-button-active' : 'generate-button'">
          Generate
        </button>
      </footer>
    </b-modal>
  </div>
</template>

<script>
import {
  Authenticate,
  groupValidateInput,
} from "./../../../../src/store/utils";
import countryIcons from "./../../../../src/assets/country/index";
import advice from "./../../../store/advice";

export default {
  name: "Argument",
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      statement: "",
      jurisdiction: "",
      countryIcons,
      // Define the mapping here
      countryCodeToName: {
        'AUS': 'Australia',
        'USA': 'USA',
        'CAN': 'Canada',
        'NZ': 'New Zealand',
        'SG': 'Singapore',
        'MY': 'Malaysia',
        'UK': 'United Kingdom',
        // Add other country codes and names as needed
      },
    };
  },
  methods: {
    getCharCount(t) { return t.length },
    selectCountry(country) {
      this.jurisdiction = country;
    },
    async GenerateArgument() {
      try {
        const isValid = await this.$validator.validateAll();
        if (!isValid) {
          this.$toast.error("Please fill in all fields");
          return;
        }

        const results = groupValidateInput([
          [this.statement, "string", ["max200"]],
          [this.jurisdiction, "jurisdiction"],
        ]);

        if (results.findIndex((x) => !x[0]) > -1) {
          return;
        }

        // Fetch locations from localStorage and validate
        const locations = localStorage.locations ? JSON.parse(localStorage.locations) : [];


        // Map the jurisdiction code to the full country name
        const jurisdictionCode = results[1][1];
        const jurisdictionName = this.countryCodeToName[jurisdictionCode];

        if (!jurisdictionName) {
          this.$toast.error("Invalid jurisdiction code selected.");
          return;
        }

        const foundLocation = locations.find((x) => x.title === jurisdictionName);

        if (!foundLocation) {
          this.$toast.error("Location not found. Please select a valid jurisdiction.");
          return;
        }

        const obj = {
          statement: results[0][1],
          jurisdiction: jurisdictionName, 
          type: "argument",
          location_id: foundLocation.id, 
          language: "en",
        };

        await advice.CreateLegalArgumentMemo(obj);
        this.$toast.success("Legal Argument created successfully!");
        this.$emit('created');
        this.$emit('close');

        Authenticate(this);
        this.$router.push({ name: "LegalAdvice" });

      } catch (error) {
        console.log(error);
      }
    }

  },
};
</script>


<style scoped>
.memo-header {
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  display: flex;
  width: 100%;
  gap: 40px;
  flex-wrap: wrap;
  padding: 16px 24px;
}

.title-container {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  justify-content: center;
}

.main-title {
  color: #383a3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
}

.subtitle {
  color: #86888d !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  margin: 4px 0 0;
}

.close-button {
  border-radius: 4px;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding: 6px;
  background: none;
  border: none;
  cursor: pointer;
}

.close-icon {
  width: 24px;
  height: 24px;
  color: #0E4485;
}

.memo-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  justify-content: center;
  padding: 12px 24px 24px;
}

.memo-form {
  width: 100%;
}

.input-section {
  border-radius: 8px;
  border: 1px solid #f2f3f3;
  background-color: #fafbfc;
  padding: 12px;
  margin-bottom: 16px;
}

.input-label {
  color: #383a3e;
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
}

.input-field {
  width: 100%;
  border: none;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  resize: none;
}
.input-field::placeholder {
  color: #86888d;
  opacity: 1;
}
.input-field:focus {
  outline: none;
}
.character-count {
  display: block;
  text-align: right;
  font-size: 14px;
  color: #86888d;
  margin-top: 8px;
}

.jurisdiction-section {
  border-radius: 8px;
  border: 1px solid #f2f3f3;
  background-color: #fff;
  padding: 8px 16px;
}

.section-title {
  font-size: 16px;
  color: #383a3e;
  font-weight: 500;
  margin: 0 0 16px;
}

.country-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.country-button {
  border-radius: 6px;
  border: 1px solid #f2f3f3;
  background-color: #fafbfc;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 18px;
  font-size: 14px;
  color: #86888d;
  font-weight: 400;
  cursor: pointer;
}
.country-button:hover,.selected{
  border: 1px solid #0E4485;
  background: #E7ECF3;
}

.country-flag {
  width: 24px;
  height: 24px;
}

.memo-footer {
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 16px;
  font: 500 16px Poppins, sans-serif;
}

.cancel-button,
.generate-button {
  border-radius: 4px;
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid transparent;
}

.cancel-button {
  background-color: #f2f3f3;
  color: #d1d2d5;
}

.generate-button {
  background: #0E44851A;
  color: #d1d2d5;
}

.cancel-button:hover{
  border: 1px solid #D1D2D5;
  color: #383A3E;
}

.generate-button:hover,
.generate-button-active {
  border-radius: 4px;
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid transparent;
  background: #0E4485;
  color: #fff;
}

@media (max-width: 991px) {
  .memo-header,
  .memo-content {
    padding: 16px 20px;
  }

  .country-list {
    gap: 10px;
  }

  .country-button {
    padding: 8px 12px;
  }
}
</style>